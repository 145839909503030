<template>
  <div class="container-fluid ">
    <div  class="animated fadeIn" v-show="body" v-html="body">
    </div>
    <no-data  class="animated fadeIn" v-show="!body" isLoading>
    </no-data>
  </div>
</template>

<script>
  import {
    mapState
  } from 'vuex';
  export default {
    props:{
      pages:{
        type: Object,
        required: true,
      }
    },
    data() {
      return {
        body: null,
        devPages:{
            es: '5feb8302cd64d407d0290f16',
            en: '5fe2697777b5fc4378e9bbc8',
        }
      }
    },
    computed: {
      ...mapState(['language'])
    },
    watch: {
      language() {
        this.fnApiGetPage();
      }
    },
    methods: {
      async fnApiGetPage() {
        let page = null
        if (process.env.NODE_ENV == 'development') {
          page = this.devPages[this.language];
        } else {
          page = this.pages[this.language];
        }
        this.body = null;
        await axios.get(`pages/${page}`).then(async (response) => {
          this.body = response.data.blocks[0].fields[0].value
        }).catch(error => {});
      },
    },
    mounted() {
      this.fnApiGetPage();
    }
  }
</script>